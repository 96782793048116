<template>

</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'Logout',
  store,
  props:["loggedin"],
  data () {
    return {
    }
  },
  created () {

  },
  mounted () {
    const self = this;
    this.$props.loggedin = false;
    this.logout();
    this.$parent.loggedin = false;
    userService.logout();
  },
  methods:{
    logout() {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('body').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/logout`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          userService.logout();
          self.$parent.loggedin = false;
          self.$props.loggedin = false;
          self.$router.push("/login");
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('body').unblock();
      });
    }
  }
}
</script>

<style scoped>

</style>
